




























import { ProductCategory } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ProductCategoryPreviewItem extends Vue {
  @Prop({ required: true }) readonly value!: ProductCategory;

  get storeFrontId() {
    return this.$route.params.id;
  }
}
