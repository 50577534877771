





































































































import StoreFrontsMixin, { StoreFrontMixinTags } from '@/mixins/http/StoreFrontsMixin';
import { Banner, BannerGroup, CategoryGroup, ProductCategory, StoreFront, StoreFrontCategoryGroup, StoreFrontSection } from '@/models';
import { easync } from '@/utils/http';
import { Vue, Component, Mixins } from 'vue-property-decorator';
import PageCodeSelect from '@/components/StoreFront/BannerGroup/PageCodeSelect.vue';
import StoreFrontSectionsMixin, { StoreFrontSectionsMixinTags } from '@/mixins/http/StoreFrontSectionsMixin';
import BannerGroupsMixin, { BannerGroupsMixinTags } from '@/mixins/http/BannerGroupsMixin';
import { showErrorToast } from '@/utils/Toast';
import BannerPreviewItem from '@/components/StoreFront/Banner/PreviewItem.vue';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import ProductCategoryPreviewItem from '@/components/ProductCategory/PreviewItem.vue';
import EmptyView from '@/components/EmptyView.vue';

@Component({
  components: {
    PageCodeSelect,
    BannerPreviewItem,
    ProductCategoryPreviewItem,
    EmptyView
  }
})
export default class StoreFrontPreviewDashboard extends Mixins(
  StoreFrontsMixin,
  BannerGroupsMixin,
  StoreFrontSectionsMixin
) {
  private storeFront: StoreFront | null = null;
  private selectedPage = '';

  private bannerGroups: BannerGroup[] = [];
  private sections: StoreFrontSection[] = [];

  @RegisterHttp(BannerGroupsMixinTags.Index) readonly bannersRequest!: RequestData;
  @RegisterHttp(StoreFrontSectionsMixinTags.Index) readonly sectionsRequest!: RequestData;

  get storeFrontId() {
    return this.$route.params.id;
  }

  async created() {
    await this.loadStoreFront();
  }

  get storeFrontShowTag() {
    return `${StoreFrontMixinTags.Show}_${this.storeFrontId}`;
  }

  get bannersLoading() {
    return this.bannersRequest?.loading;
  }

  get sectionsLoading() {
    return this.sectionsRequest?.loading;
  }

  private async onPageSelected(page: string) {
    this.selectedPage = page;
    await this.loadBannerGroups();
    await this.loadSections();
  }

  private async loadStoreFront() {
    const [data, errors] = await easync(
      this.getStoreFront(this.storeFrontId, this.storeFrontShowTag)
    );

    if (data) {
      this.storeFront = data
    } else {
      this.storeFront = null;
    }
  }

  get banners(): Banner[] {
    if (!this.bannerGroups.length) {
      return [];
    }

    let banners: Banner[] = []
    this.bannerGroups.forEach((el: BannerGroup) => {
      banners = banners.concat(el.banners);
    });

    return banners;
  }

  get categoryGroups(): CategoryGroup[] {
    if (!this.sections.length) {
      return [];
    }

    let items: CategoryGroup[] = [];
    this.sections.forEach((el: StoreFrontSection) => {
      items = items.concat(el.categoryGroups);
    });
    console.log('sections', this.sections);
    console.log('items', items);
    return items;
  }

  get payload() {
    return {
      filter: {
        storeFront: this.storeFrontId,
        page: this.selectedPage
      }
    }
  }

  private async loadBannerGroups() {
    const [data, errors] = await easync(
      this.getBannerGroups(this.payload, BannerGroupsMixinTags.Index)
    );

    if (data) {
      this.bannerGroups = data;
    } else {
      this.bannerGroups = [];
      showErrorToast('Sì è verificato un errore con i BannerGroups');
    }
  }

  private async loadSections() {
    const payload = this.payload;
    (payload as any).include = 'category_groups.categories';

    const [data, errors] = await easync(
      this.getStoreFrontSections(payload, StoreFrontSectionsMixinTags.Index)
    );

    if (data) {
      this.sections = data;
    } else {
      this.sections = [];
      showErrorToast('Sì è verificato un errore con le sezioni');
    }
  }

  private productCategories(section: StoreFrontSection): ProductCategory[] {
    let categories: ProductCategory[] = [];

    section.categoryGroups.forEach(el => {
      categories = categories.concat(el.categories);
    });
    console.log('section', section);
    console.log('categories', categories);
    return categories;
  }
}
