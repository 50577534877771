import { render, staticRenderFns } from "./PreviewItem.vue?vue&type=template&id=3be20837&"
import script from "./PreviewItem.vue?vue&type=script&lang=ts&"
export * from "./PreviewItem.vue?vue&type=script&lang=ts&"
import style0 from "./PreviewItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports