














import { Banner } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BannerPreviewItem extends Vue {
  @Prop({ required: true }) readonly banner!: Banner;

  get storeFrontId() {
    return this.$route.params.id;
  }

  private goToBannerShow() {
    this.$router.push({
      name: 'banners_update',
      params: { id: this.storeFrontId, bannerId: this.banner.id }
    });
  }
}
